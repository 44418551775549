import { Components } from '@mui/material';

import { colors } from '../colors';

export const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
    disableFocusRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '& svg': {
        display: 'block',
        margin: 'auto',
      },
      '&.Mui-disabled': {
        color: colors['gray-500'],
      },
      '&.MuiButton-root': {
        fontFamily: 'Avenir Next Demi Bold',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
        textTransform: 'none',
      },
      '&.MuiButton-root > div': {
        padding: 8,
      },
      '&.MuiButton-sizeMedium': {
        borderRadius: '8px',
        gap: 4,
        padding: 8,
      },
      '&.MuiButton-sizeSmall': {
        borderRadius: '8px',
        gap: 4,
        padding: 8,
      },
      '&.MuiButton-textError': {
        backgroundColor: colors['transparent'],
        border: 'none',
        color: colors['red-450'],
      },
      '&.MuiButton-textError:active': {
        backgroundColor: colors['red-200'],
      },
      '&.MuiButton-textError:focus': {
        backgroundColor: colors['red-100'],
      },
      '&.MuiButton-textError:hover': {
        backgroundColor: colors['red-100'],
      },
      '&.MuiButton-textPrimary': {
        backgroundColor: colors['transparent'],
        border: 'none',
        color: colors['blue-500'],
      },
      '&.MuiButton-textPrimary:active': {
        backgroundColor: colors['darkBlue-200'],
      },
      '&.MuiButton-textPrimary:focus': {
        backgroundColor: colors['darkBlue-100'],
      },
      '&.MuiButton-textPrimary:hover': {
        backgroundColor: colors['blue-700'],
      },
    },
  },
};
